import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import { Avantages } from "../components/Carrieres/Avantages";
import { TypeAnimation } from "react-type-animation";
import { MainButton } from "../components/Buttons/Buttons";
import { Extras } from "../components/Carrieres/Extras";
import { Fiche } from "../components/Carrieres/Fiche";
import { AvantageShow } from "../components/Carrieres/AvantageShow";
import { AvantagesData } from "../components/Carrieres/AvantagesData";
// import { Link } from "react-scroll";
// import ReactGA from "react-ga4";


const FICHE_NAME = {
  CC: "CC",
  AP: "AP",
  APSansPermis: "APSansPermis",
};

const FICHES = {
  CC: {
    tag: "Agent de contact",
    ficheid: "01",
    title: "Agent de contact",
    subtitle: "Je n'ai pas de permis de représentant en assurance",
    lieu: "Québec",
    horaire: "Temps plein, temps partiel",
    salaire: "24,50$/H",
    salairedescription: "",
    bonification: "",
    desc: (
      <>
      
        <p style={{ fontFamily: "factor-a-light" }}>Le poste d'agent de contact consiste à : </p>
        <ul className="dashed" style={{ fontFamily: "factor-a-light" }}>
          <li style={{ fontFamily: "factor-a-light" }}> Récolter les dates de renouvellement de l'assurance automobile et habitation de nos clients potentiels.</li>
          <li style={{ fontFamily: "factor-a-light" }}> Support à la perception des comptes clients.</li>
          <li style={{ fontFamily: "factor-a-light" }}> Support administratif.</li>
        </ul>

        <p style={{ fontFamily: "factor-a-light" }}>
          AGP Assurance, c'est un cabinet d'assurance de dommage basé à Québec composé 
          d'une équipe de gens passionnés. On se différencie par une marque employeur forte, 
          une équipe de travail dynamique et compétitive qui n'a pas peur des défis. 
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>Le poste d'Agent de Contacts clients consiste à :</p>
        <ul className="dashed" style={{ fontFamily: "factor-a-light" }}>
          <li style={{ fontFamily: "factor-a-light" }}>Récolter les dates de renouvellement de l'assurance automobile et habitation de clients potentiels.</li>
          <li style={{ fontFamily: "factor-a-light" }}>Support à la perception des comptes clients</li>
          <li style={{ fontFamily: "factor-a-light" }}>Support administratif du cabinet</li>
        </ul> 

        <p style={{ fontFamily: "factor-a-light" }}>
          À titre de premier contact, les employés du Centre de contacts clients sont responsables 
          d'établir un lien de confiance avec la clientèle, teinté de jovialité et de professionnalisme. 
          Il s'agit du premier maillon d'un important travail à la chaîne.
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          Chez AGP, le développement des talents à l'interne est au cœur des stratégies. Tu vois à plus long terme? 
          Cet emploi pourrait être le début de ta carrière avec nous. Nous offrons un programme de formation 
          rémunéré qui pourrait rapidement t'amener à décrocher une promotion vers notre équipe de courtiers 
          en assurance de dommages des particuliers. 
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          C'est beaucoup plus qu'un boulot, c'est une opportunité à saisir.
        </p>

        <p style={{ fontFamily: "factor-a-bold" }}>Exigences :</p>
        <ul className="dashed" style={{ fontFamily: "factor-a-light" }}>
          <li style={{ fontFamily: "factor-a-light" }}>Tu dois détenir un diplôme d'étude collégiale (ou une équivalence de 54 crédits) ou être en voie de l'obtenir</li>
          <li style={{ fontFamily: "factor-a-light" }}>Être disponible à travailler 15h par semaine minimum, sur 3 jours</li>
        </ul>

        <p style={{ fontFamily: "factor-a-bold" }}>Profil de personne recherché :</p>
        <ul className="dashed" style={{ fontFamily: "factor-a-light" }}>
          <li style={{ fontFamily: "factor-a-light" }}>Dynamique</li>
          <li style={{ fontFamily: "factor-a-light" }}>Ayant une bonne capacité de communication et vulgarisation</li>
          <li style={{ fontFamily: "factor-a-light" }}>Qui aime travailler en équipe</li>
          <li style={{ fontFamily: "factor-a-light" }}>Mobilisé par des objectifs de performance</li>
        </ul>

        <p style={{ fontFamily: "factor-a-light" }}>
          Comme tu peux voir, les exigences du poste sont très limitées. Chez AGP, on priorise ta personnalité. 
          On cherche d'abord une cohésion entre tes valeurs et celles de l'entreprise.
        </p>


      </>
    ),
  },

  AP: {
    tag: "Conseiller particulier",
    ficheid: "02",
    title: "Courtier en assurance de dommages",
    subtitle: "J'ai déjà mon permis de représentant",
    lieu: "Québec",
    horaire: "Temps plein, temps partiel",
    salaire: "30$ à 33$/H",
    salairedescription: "avant bonification",
    bonification: "Mensuelle",
    desc: (
      <>

        <p style={{ fontFamily: "factor-a-light" }}>
          AGP Assurance, c'est un cabinet d'assurance de dommage basé à Québec composé 
          d'une équipe de gens passionnés. On se différencie par une marque employeur forte, 
          une équipe de travail dynamique et compétitive qui n'a pas peur des défis. 
        </p>

        <p style={{ fontFamily: "factor-a-bold" }}>Responsabilités : </p>
        <ul className="dashed" style={{ fontFamily: "factor-a-light" }}>
          <li style={{ fontFamily: "factor-a-light" }}>Développement d'affaires : offrir des soumissions d'assurance aux clients avec les produits de nos partenaires.</li>
          <li style={{ fontFamily: "factor-a-light" }}>Responsable de l'expérience client : conseiller, fidéliser et effectuer des modifications aux contrats existants.</li>
          <li style={{ fontFamily: "factor-a-light" }}>Toutes autres tâches connexes à l'assurance : élargir l'éventail de responsabilités.</li>
        </ul>

        <p style={{ fontFamily: "factor-a-light" }}>
          Ainsi, pas besoin de choisir. Notre équipe profite des bonus de ventes tout en ayant une diversité d'appels au quotidien.
        </p>

        <p style={{ fontFamily: "factor-a-bold" }}>Exigences : </p>
        <ul className="dashed" style={{ fontFamily: "factor-a-light" }}>
          <li style={{ fontFamily: "factor-a-bold" }}>
            OBLIGATOIRE : Détenir un permis en assurance de dommages des particuliers délivré par l'Autorité des marchés financier (AMF).
          </li>
          <li style={{ fontFamily: "factor-a-light" }}>Avoir une bonne capacité de communication et de vulgarisation</li>
          <li style={{ fontFamily: "factor-a-light" }}>Personne dynamique</li>
          <li style={{ fontFamily: "factor-a-light" }}>Aimer travailler en équipe</li>
          <li style={{ fontFamily: "factor-a-light" }}>Être compétitif</li>
          <li style={{ fontFamily: "factor-a-light" }}>Bilinguisme (un atout)</li>
        </ul>

        <p style={{ fontFamily: "factor-a-light" }}>
          Comme tu peux voir, les exigences du poste sont très limitées. Chez AGP, on priorise ta personnalité à l'ancienneté. 
          On cherche d'abord une cohésion entre tes valeurs et celles de l'entreprise.
        </p>

      </>
    ),
  },

  APSansPermis: {
    tag: "Conseiller particulier sans permis",
    ficheid: "03",
    title: "Courtier en assurance des particuliers",
    subtitle: "Je n'ai pas mon permis de représentant mais je désire l'obtenir",
    lieu: "Québec",
    horaire: "25h à 35h par semaine",
    salaire: "28$ à 33$/H",
    salairedescription: "(débutant à 51 000$)",
    bonification: "Mensuelle",
    desc: (
      <>
        <p style={{ fontFamily: "factor-a-light" }}>
          Tu aimerais débuter ta carrière en assurance mais tu ne sais pas par où commencer? 
          Pas de problème, chez AGP, le développement des talents à l'interne est au cœur des 
          stratégies. En misant sur tes compétences, on pourrait t'offrir de réelles opportunités 
          à la hauteur de tes ambitions. Tu vois à plus long terme? Cet emploi pourrait être le début 
          de ta carrière.
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          Nous offrons un programme de formation interne rémunéré permettant aux candidats d'obtenir 
          rapidement leur certificat de représentant en assurance de dommage.
        </p>

        <p style={{ fontFamily: "factor-a-bold" }}>Responsabilités : </p>
        <ul className="dashed" style={{ fontFamily: "factor-a-light" }}>
          <li style={{ fontFamily: "factor-a-light" }}>Développement d'affaires : offrir des soumissions d'assurance aux clients avec les produits de nos partenaires.</li>
          <li style={{ fontFamily: "factor-a-light" }}>Responsable de l'expérience client : conseiller, fidéliser et effectuer des modifications aux contrats existants.</li>
          <li style={{ fontFamily: "factor-a-light" }}>Toutes autres tâches connexes à l'assurance : élargir l'éventail de responsabilités.</li>
        </ul>

        <p style={{ fontFamily: "factor-a-light" }}>
          Ainsi, pas besoin de choisir. Notre équipe profite des bonus de ventes tout en ayant une diversité d'appels au quotidien.
        </p>

        <p style={{ fontFamily: "factor-a-bold" }}>Exigences : </p>
        <ul className="dashed" style={{ fontFamily: "factor-a-light" }}>
          <li style={{ fontFamily: "factor-a-bold" }}>OBLIGATOIRE : Satisfaire à l'un des critères de formations minimales exigés par l'AMF (Autorité des marchés financiers)</li>
          <li style={{ fontFamily: "factor-a-light" }}>Avoir une bonne capacité de communication et de vulgarisation</li>
          <li style={{ fontFamily: "factor-a-light" }}>Aimer travailler en équipe</li>
          <li style={{ fontFamily: "factor-a-light" }}>Être compétitif</li>
          <li style={{ fontFamily: "factor-a-light" }}>Bilinguisme (un atout)</li>
        </ul>

        <p style={{ fontFamily: "factor-a-light" }}>
          Comme tu peux voir, les exigences du poste sont très limitées. Chez AGP, on priorise ta personnalité 
          à l'ancienneté. On cherche d'abord une cohésion entre tes valeurs et celles de l'entreprise.
        </p>
       



      </>
    ),
  },
};

const FICHES_DATA = {
  "01": FICHES[FICHE_NAME.CC],
  "02": FICHES[FICHE_NAME.AP],
  "03": FICHES[FICHE_NAME.APSansPermis],
};

export default function Carrieres() {
  const [state, setState] = useState({
    showFiche: false,
    fiches: FICHES.CC,
  });

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    // ReactGA.pageview(window.location.pathname);

    const ficheid = params.get("fiche");

    if (ficheid && ficheid in FICHES_DATA) {
      setState({
        showFiche: true,
        data: FICHES_DATA[ficheid],
      });
    }
  }, []);

  const middleAvantageDataIndex = Math.ceil(AvantagesData.length / 2);

  return (
    <div className="large-screen-wrapper">
      <div className="main">
        <div className="main-container">
          <Navbar
            activateSecondClass={true}
            secondClassName="main-button-inversed"
            tabSelected={4}
          />
          <div data-scroll-section id="page-carriere" className="wrapper">
            {state.showFiche ? (
              <Fiche {...state.data} handleBack={() => { setState({ ...state, showFiche: false });}} {...state.data} />
            ) : (
              <div>
                <section id="carrieres-top">
                  <div>
                    <h2>
                      <span >Travailler</span> chez AGP c'est
                      <span className="secondary-dark-orange">
                        <TypeAnimation
                          sequence={[
                            "surprenant",
                            1000,
                            "le fun",
                            2000,
                            "différent",
                            1500,
                            "du bon monde",
                            3000,
                            "compétitif",
                          ]}
                          speed={45}
                          wrapper="span"
                          cursor={true}
                          repeat={Infinity}
                          style={{ fontSize: "1em" }}
                        />
                      </span>
                    </h2>
                  </div>

                  <AvantageShow
                    leftData={AvantagesData.slice(0, middleAvantageDataIndex)}
                    rightData={AvantagesData.slice(middleAvantageDataIndex)}
                  />
                </section>

                <section id="carrieres-jobs">
                  <h2>
                    Offres d'emploi
                  </h2>
                  <ul>

                    <li data-fiche-id="1">
                      <div>
                        Agent de contact{" "}
                        <div style={{fontSize: 20, paddingTop: 15, color: "#42AAF5" }}>Je n'ai pas de permis de représentant</div>
                      </div>
                      <span>Québec</span>
                      <MainButton
                        handle={() => {
                          setState({
                            showFiche: true,
                            data: FICHES[FICHE_NAME.CC],
                          });
                          window.scrollTo(0, 0);
                        }}
                        name="POSTULER"
                      />
                    </li>

                    <li data-fiche-id="1">
                      <div>
                        Courtier en assurance de dommages{" "}
                        <div style={{fontSize: 20, paddingTop: 15, color: "#42AAF5" }}>Avec permis de représentant</div>
                      </div>
                      <span>Québec</span>
                      <MainButton
                        handle={() => {
                          setState({
                            showFiche: true,
                            data: FICHES[FICHE_NAME.AP],
                          });
                          window.scrollTo(0, 0);
                        }}
                        name="POSTULER"
                      />
                    </li>

                    <li data-fiche-id="1">
                      <div>
                        Courtier en assurance de dommages{" "}
                        <div style={{fontSize: 20, paddingTop: 15, color: "#42AAF5" }}>Sans permis de représentant</div>
                      </div>
                      <span>Québec</span>
                      <MainButton
                        handle={() => {
                          setState({
                            showFiche: true,
                            data: FICHES[FICHE_NAME.APSansPermis],
                          });
                          window.scrollTo(0, 0);
                        }}
                        name="POSTULER"
                      />
                    </li>


                  </ul>
                </section>

                <section id="carrieres-msg">
                  <h2>
                    On fait confiance à l'équipe comme aucune autre entreprise ne
                    peut le faire.
                  </h2>
                  <div className="msg">
                    <p>#Power to the people</p>
                  </div>
                </section>

                <section id="carrieres-extra">
                  <h2>Nos histoires de passion</h2>
                  <Extras />
                </section>

                <section id="carrieres-avantages">
                  <Avantages />
                </section>

                
              </div>
            )}
          </div>
        </div>
        <div className="main-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
